<template>
  <div>
    <v-card class="mb-6">
      <v-card-title> Tutorial </v-card-title>
      <v-card>
        <v-divider class="mt-4"></v-divider>
        <!-- using youtube video only as placeholder -->
        <div class="container">
            <iframe width="800" height="700" src="https://www.youtube.com/embed/LfWpPRId5N0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoUrl: 'https://www.youtube.com/watch?v=LfWpPRId5N0',
      // Reemplaza 'VIDEO_ID' con el ID real del video que deseas mostrar
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
//   align-items: center;
//   height: 100vh; /* Ajusta la altura según tus necesidades */
}
</style>
